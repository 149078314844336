<!--
 * @Author: your name
 * @Date: 2020-06-01 10:03:35
 * @LastEditTime: 2020-12-14 10:59:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\views\production\Production.vue
-->
<template>
  <div class="productionlistdetails">
    <!--头部banner图-->
    <div class="banner">
      <img :src="webpic" alt  v-if="webpic != ''"/>
       <img :src="pic" alt  v-if="webpic == ''"/>
      <div class="banner_title" v-if="webpic == ''">
        <h2>匠师定制,揭开幕后设计艺术</h2>
        <p>Design art, Design philosophy</p>
        <h4>追思设计师，一起探索你想知道的珠宝设计幕后的秘密</h4>
       
      </div>
    </div>
    <!--列表标题-->
    <div class="statement">
      <p>OUR PRODUCTS</p>
      <p>我们的产品</p>
    </div>
    <!--产品列表-->
    <div class="mainlist">
      <!--产品列表分类-->
      <div class="dz">
        <div class="woshi" v-if="show"></div> 
        <div class="list_head">
          <div v-for="(item, index) in mainlist" :key="index">
            <dl @click.stop="dipalyblock(index)">
              <dd>{{ item.name }}</dd>
              <dt>
                <img :src="item.imgUrl" alt />
              </dt>
            </dl>
          </div>
        </div>
        <!--饰品分类-->

        <div :class="block ? 'ornament' : 'none'" @click.stop="() => {}">
          <ul>
            <li
              v-for="item in ornamentlist.ornament"
              :key="item.id"
              @click="tagIndex('type', item.id)"
              :class="slotlist.type == item.id ? 'actived' : ''"
            >{{ item.name }}</li>
          </ul>
          <!--裸钻定制-->
          <div class="customization" @click='luoshi()'>
            <span>纪念裸钻定制</span>
          </div>
        </div>

        <!--主石重量-->
        <div :class="weight ? 'ornament width' : 'none'" @click.stop="() => {}">
          <ul>
            <li
              v-for="item in ornamentlist.weight"
              :key="item.id"
              @click="tagIndex('carat', item.ct)"
              :class="slotlist.carat == item.ct ? 'actived' : ''"
            >
              <!--:class="changestyle[idx] == idx?'actived':''" @click="changestyle[idx] == idx"-->
              {{ item.ct }}ct
            </li>
          </ul>
        </div>
        <!--主石形状-->
        <div :class="shape ? 'ornament width height' : 'none'" @click.stop="() => {}">
          <div
            v-for="item in ornamentlist.shape"
            :key="item.id"
            class="shape"
            @click="tagIndex('dtype', item.id)"
            :class="slotlist.dtype == item.id ? 'actived' : ''"
          >{{ item.name }}</div>
        </div>
        <!--价格筛选-->
        <div :class="price ? 'ornament width price heightpri' : 'none'" @click.stop="() => {}">
          <ul>
            <li
              v-for="(item,index) in ornamentlist.price"
              :key="item.id"
              @click="slotlist.three = item.id;tagIndex('price', index)"
              :class="slotlist.three == item.id ? 'actived' : ''"
            >{{ item.price }}</li>
          </ul>
          <div class="exactprice">
            <div class="exactmain">
              <input type="text" v-model="minprice" placeholder="人民币" />
              <div></div>
              <input type="text" v-model="maxprice"  placeholder="人民币" />
               <el-button  :disabled="!(minprice*1<maxprice*1)" @click="roleSearch()">确定</el-button>
            </div>
          </div>
        </div>
      </div>
      <!--产品列表-->
      <div class="product" v-if="datalist.length > 0">
        <div class="productlast" v-for="(item, index) in datalist" :key="item.id">
          <!--产品图片-->
          <div class="img" @click="linkdatalis(index)">
            <img :src="item.pic.picUrl" alt class="img-item" />
            <!--收藏-->
            <img
              :src="item.iscare == 1 ? lovered : loveno"
              alt
              class="collect"
              @click.stop="
              
                collect(item,index);
              "
            />
          </div>
          <!--产品介绍-->
          <div class="font">
            <p>{{ item.title }}</p>
            <div class="borders"></div>
            <p>{{ item.desc }}</p>
          </div>
          <!--加入购物车-->
          <div class="car" @click="addcar(item.id,$event)">
            <span class="price">{{ item.minpriceStr }}</span>
            <button class="addcar">添加至购物车</button>
          </div>
        </div>
      </div>
      <div v-else class="without">暂无商品</div>
      <!--分页-->
      <div class="page-box">
        <el-pagination
          layout="prev, pager, next"
          :total="listleng"
          :page-size="size"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { getlist, add, collect } from "@/service/api.js";
export default {
  name: "Production",
  components: {},
  data() {
    return {
      mainlist: [
        { name: "饰品类别", imgUrl: require("@/assets/images/aroow copy.png") },
        { name: "主石重量", imgUrl: require("@/assets/images/aroow copy.png") },
        { name: "主石形状", imgUrl: require("@/assets/images/aroow copy.png") },
        { name: "价格筛选", imgUrl: require("@/assets/images/aroow copy.png") }
      ],
      ornamentlist: {
        ornament: [
          { id: 0, name: "全部" },
          { id: 3, name: "项链" },
          { id: 2, name: "戒指" },
          { id: 1, name: "手链" },
          { id: 4, name: "耳饰" }
        ],
        weight: [
          { id: 1, ct: 0.3 },
          { id: 2, ct: 0.5 },
          { id: 3, ct: 0.8 },
          { id: 4, ct: 1 },
          { id: 5, ct: 2 }
        ],
        shape: [
          { id: -1, name: "全部" },
          { id: '0', name: "圆形" },
          { id: 1, name: "公主方形" },
          { id: 2, name: "祖母绿形" },
          { id: 3, name: "雷迪恩形" },
          { id: 4, name: "垫形" },
          { id: 5, name: "梨形" },
          { id: 6, name: "阿斯切形" },
          { id: 7, name: "心形" }
        ],
        price: [
          { id: 1, price: "5000以下" },
          { id: 2, price: "5001-10000" },
          { id: 3, price: "10001-20000" },
          { id: 4, price: "20001-40000" },
          { id: 5, price: "40000以上" }
        ]
      },
      datalist: [],
      arr: [],
      listleng:0,
      page: 1,
      loveno: require("@/assets/images/loveccc.png"),
      lovered: require("@/assets/images/lovered.png"),
      webpic: '',
      pic:require('@/assets/images/star-bg.png'),
      block: false, //分类
      weight: false, //重量
      shape: false, //形状
      price: false, //价格
      changestyle: [], //选中样式
      slotlist: {
        carat: "",
        type: "",
        dtype: "",
        four: "",
        price:'',
      },
      sify: 0, //分类
      show: false,
      size:8,
       maxprice:"",
      minprice:'',
    };
  },
  methods: {
    // 收藏
    collect(item, index) {
      if (this.$store.getters["user/getToken"]) {
        let type = item.iscare == 1 ? 2 : 1;
        collect({
          type,
          productid: item.id
        }).then(() => {
          this.datalist[index].iscare = type;
          
        });
      } else {
        this.loading();
        this.$router.push({
          path: "/createLogin.html?url=/production.html?ids=" + this.ids
        });
      }
    },
    tagIndex(name, type) {
      (this.block = false), //分类
        (this.weight = false), //重量
        (this.shape = false), //形状
        (this.price = false), //价格
        this.page = 1
        this.size = 8
this.slotlist[name] = type
      if(name == 'price'){
         let object = this.ornamentlist.price[type].price
        if(type == 0 || type == 4){
            if(type == 0){
              this.slotlist.minprice = 0
              this.slotlist.maxprice = 5000
            }
            if(type == 4){
              this.slotlist.minprice = 40000
              this.slotlist.maxprice = 99999999
            }
        }else{
          let a = object.split('-')
          this.slotlist.minprice = parseFloat(a[0])
          this.slotlist.maxprice = parseFloat(a[1])
        }
      }
     
     
      this.getlist();
      // console.log(name,type)
      // console.log(this.slotlist);
    },



    roleSearch(){
         this.slotlist.minprice = this.minprice
          this.slotlist.maxprice = this.maxprice
     this.getlist();
    },
    // 数据请求
    getlist() {
      // console.log(123);
      getlist({ start: (this.page - 1) * this.size, ...this.slotlist,size:this.size }).then(res => {
        console.log(res);
        if(res.attachment.bg){
           this.webpic = res.attachment.bg.webpic.picUrl;
        }else{
          this.webpic = []
        }
        this.listleng = res.attachment.total;
        let arr = res.attachment.datas || [];
        this.datalist = arr;
        // console.log(this.webpic)
      });
    },
    // 分页
    handleCurrentChange(index) {
      // console.log($(".statement"));
      document.getElementById("sapp").scrollTop =
        document.getElementsByClassName("statement")[0].offsetTop -
        document.getElementsByClassName("tab-list")[0].offsetHeight;
      this.page = index;
      this.getlist();
    },
    //添加购物车
    addcar(e, event) {
      // console.log(e);
      if (this.$store.getters["user/getToken"]) {
        let user = this.$store.getters["user/getuserdata"];
        add({
          uid: user.uid,
          pnum: 1,
          skuid: 0,
          sizeid:0,
          productid: e
        }).then(res => {
          // console.log(res);
          // if (res.status == 400) {
            // this.$notify.error({
            //   title: "错误",
            //   message: res.message,
            //   offset: 100
            // });
          // } else if (res.status == 402) {
            // this.$notify.error({
            //   title: "错误",
            //   message: res.message,
            //   offset: 100
            // });
          // } else  
          if (res.status == 200) {
            //购物车动画

            var offset = $(".spw").offset();
            var addcar = $(this);
            var img = $(event.target)
              .parent()
              .parent()
              .find(".img-item")
              .attr("src");
            var flyer = $(
              '<img class="u-flyer" src="' + img + '" style="z-index:99999" ;border-radius: 50%;width:50px;height:50px>'
            );
            flyer.fly({
              start: {
                left: event.pageX, //开始位置（必填）#fly元素会被设置成position: fixed
                top: event.pageY //开始位置（必填）
              },
              end: {
                left: offset.left + 10, //结束位置（必填）
                top: offset.top + 10, //结束位置（必填）
                width: 0, //结束时宽度
                height: 0 //结束时高度
              },
              onEnd: function() {
                //结束回调
                $("#msg")
                  .show()
                  .animate({ width: "250px" }, 200)
                  .fadeOut(1000); //提示信息
                addcar
                  .css("cursor", "default")
                  .removeClass("orange")
                  .unbind("click");
              }
            });

            this.$store.commit("user/SETCAR", res.attachment.total);
           
          } else if (res.status == 204) {
            // this.$notify.error({
            //   title: "错误",
            //   message: res.message,
            //   offset: 100
            // });
            let loading = this.$loading({
              lock: true,
              text: res.message,
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)"
            });
            setTimeout(() => {
              loading.close();
              this.$router.push({
                path: "/productInfo.html",
                query: {
                  id: e
                }
              });
            }, 300);
          }
        });
      } else {
        // this.$notify.error({
        //   title: "未登录",
        //   message: "您还未登录，赶紧去登陆吧",
        //   offset: 100
        // });
        let loading = this.$loading({
          lock: true,
          text: "您还未登录，正在跳转登陆页面...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        setTimeout(() => {
          loading.close();
          this.$router.push({
            path: "/createLogin.html?url=/production.html"
          });
        }, 300);
      }
    },
    // tab
    dipalyblock(i) {
      // console.log(i);
      if (i == 0) {
        this.block = !this.block;
        this.weight = false;
        this.price = false;
        this.shape = false;
      }
      if (i == 1) {
        this.weight = !this.weight;
        this.block = false;
        this.shape = false;
        this.price = false;
      }
      if (i == 2) {
        this.shape = !this.shape;
        this.block = false;
        this.weight = false;
        this.price = false;
      }
      if (i == 3) {
        this.price = !this.price;
        this.block = false;
        this.weight = false;
        this.shape = false;
      }
    },
    // 分类
    linkdatalis(i) {
      // this.$router.push({
      //   path: "/particulars",
      //   query: {
      //     ids: this.datalist[i].id
      //   }
      // });
      let { href } = this.$router.resolve({
          path: "/productInfo.html",
           query: {
          id: this.datalist[i].id 
        }
      });
      window.open(href, '_blank');
    },
    //loading
    loading() {
      let loading = this.$loading({
        lock: true,
        text: "您还未登录，正在跳转登陆页面...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      setTimeout(() => {
        loading.close();
      }, 300);
    },
    gets() {
      getlist({
        start: (this.page - 1) * this.size,
        type: this.sify,
        size: this.size
      }).then(res => {
        // console.log(res);
        if(res.attachment.bg){
           this.webpic = res.attachment.bg.webpic.picUrl;
        }else{
          this.webpic = []
        }
        this.listleng = res.attachment.total;
        this.datalist = res.attachment.datas || [];
      });
    },
    //裸石定制
    luoshi(){
      // this.$router.push({path:'/diamondorder'})
      let { href } = this.$router.resolve({
        path: "/diamondorder",
          query: {
              id: 1
          }
      });
      window.open(href, '_blank');
    }
  },
  watch: {
    "$route.query": function(to) {
      this.sify = to.sify;
      this.gets();
    }
  },
  mounted() {
    this.webpic = ['1']
    document.body.onclick = () => {
      this.price = false;
      this.block = false;
      this.weight = false;
      this.shape = false;
    };
    this.sify = this.$route.query.sify;
    this.$set(this.slotlist, "type", this.sify);
    if (this.$route.query.sify) {
      this.gets();
    } else {
      this.getlist();
    }
    
    // console.log(this.$route.query.sify);
  }
};
</script>

<style lang="less">
.el-pagination button:disabled{
  cursor: text !important;
}
.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{
  cursor: text !important;
  background: #921d22;
  color: #fff;
}
.u-flyer {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: fixed;
  z-index: 9999;
}
.productionlistdetails {
  .woshi {
    width: 100%;
    height: 80px;
    position: absolute;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    top: -24px;
    left: 0;
  }
  .banner {
    // width: 1569px;
     height: 596px;
    display: flex;
    justify-content: center;
    margin: 1vw auto;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      min-width: 200px;
      object-fit: cover;
    }
  }
  .banner_title {
    position: absolute;
    left: 8vw;
    top: 7vw;
    h2 {
      font-weight: 600;
      font-size: 1.4vw;
      letter-spacing: 0.6vw;
      line-height: 5vw;
    }
    p {
      font-size: 0.8vw;
      line-height: 2vw;
    }
    h4 {
      font-weight: 500;
      font-size: 0.8vw;
      line-height: 4vw;
    }
  }
  .statement {
    width: 100%;
    text-align: center;
    padding: 40px 0;
    p {
      font-size: 1.5vw;
      color: #666666;
    }
  }
  .mainlist {
    width: 1260px;
    margin: 0 auto;
    margin-bottom: 10vw;
    .dz {
      width: 100%;
      height: 3vw;
      position: relative;
      z-index: 9;
    }
    .list_head {
      width: 97%;
      margin: 0 auto;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-bottom: 1px solid #ccc;
      position: relative;
      div {
        width: 25%;
        z-index: 99;

        dl {
          display: flex;
          justify-content: space-around;
          &:hover {
            cursor: pointer;
          }
          dt {
            width: 10%;
            img {
              width: 0.6vw;
              height: 0.4vw;
            }
          }
          dd {
            width: 50%;
            font-weight: 500;
            text-align: right;
          }
        }
      }
    }
  }
  .product {
    width: 100%;
    
  }
  .productlast {
    width: 22%;
    height: 500px;
    margin: 30px 18.9px;
    display: inline-block;
    .img {
      width: 100%;
      height: 60%;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden;
      &:hover {
        cursor: pointer;
      }
      img:first-child {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .font {
    p {
      text-align: center;
      &:first-child {
        font-weight: 300;
        font-size: 1.2vw;
        line-height: 2.4vw;
        margin-top: 1vw;
        height: 2vw;/* no */
        overflow: hidden;
      }
      &:last-child {
        font-size: 0.8vw;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.1vw;
        height: 20px;
      }
    }
    .borders {
      width: 2vw;
      height: 1px;
      border-bottom: 1px solid red;
      margin: 0.6vw auto;
    }
  }
  .car {
    width: 100%;
    height: 42px;
    margin-top: 1.9vw;
    display: flex;
    justify-content: space-around;
    background: #333333;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background: #921d22;
      cursor: pointer;
      .addcar {
        background: #921d22;
      }
    }
    .price {
      height: 100%;
      font-size: 0.8vw;
      line-height: 42px;
      padding-left: 0.6vw;
      color: #fff;
    }
    .addcar {
      outline: none;
      border: none;
      height: 100%;
      font-size: 0.6vw;
      background: #333333;
      color: #fff;
      cursor: pointer;
    }
  }
  .collect {
    width: 1vw;
    height: 1vw;
    position: absolute;
    right: 1vw;
    top: 1vw;
  }
  .page-box {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .page-box .el-pagination .el-pager li {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    line-height: 44px;
    color: #999999;
    background: #e5e5ee;
    margin: 0.2vw;
  }
 
  .page-box .el-pagination .btn-next,
  .page-box .el-pagination .btn-prev {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    margin: 0.2vw;
    background: #e5e5e5;
  }
  .page-box .active {
    background: #c2c2c2 !important;
    color: #fff !important;
  }
  .ornament {
    width: 100%;
    height: 104px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    border-radius: 2px;
    animation: trannone 0.5s;
    display: block;
    ul {
      width: 80%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      float: left;
      li {
        font-size: 0.85vw;
        font-weight: 500;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .customization {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      float: right;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .width {
    ul {
      width: 100%;
    }
  }
  .height {
    height: 120px;
  }
  .none {
    display: none;
  }

  @keyframes trannone {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .shape {
    width: 20%;
    float: left;
    height: 40%;
    text-align: center;
    line-height: 4vw;
    &:hover {
      cursor: pointer;
    }
  }
  .actived {
    color: red;
  }
  .price {
    ul {
      width: 100%;
      height: 70%;
      text-align: center;

      li {
        width: 20%;
        float: left;
      }
    }
  }
  .heightpri {
    height: 130px;
  }
  .exactprice {
    width: 87%;
    margin: 0 auto;
    .exactmain {
      width: 35%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
    }
    input {
      width: 5.5vw;
      height:1.5vw;
      background: #f5f5f5;
      border: none;
      outline: none;
      padding-left: 0.5vw;
      border-radius: 2px;
      font-size: 0.6vw;
      margin: 0 15px 0 0;
    }
    button {
      background: #921d22;
      width: 150px;
      height: 1.5vw;
      line-height: 7px;
      border: none;
      display: block;
      color: #fff;
      border-radius: 2px;
      font-size: 14px;
      outline: none;
    }
    div {
      width: 80px;
      height: 1px;
      border: 1px solid #000;
    }
  }
  .without {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 5vw;
  }
}
@media screen and(max-width: 1000px) {
  .product .productlast {
    width: 30% !important;
    height: 500px !important;
    margin-left: 20px;
    .font p:last-child {
      height: 30px;
      line-height: 30px;
    }
    .img {
      width: 100%;
      height: 400px !important;
      img:nth-child(1) {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and(max-width: 800px) {
  .product .productlast {
    width: 46% !important;
    height: 600px !important;
    margin: 40px 20px;
    .font p:last-child {
      height: 30px;
      line-height: 30px;
      font-size: 20px;
    }
    .font p:first-child {
      font-size: 26px;
      height: 30px;
      line-height: 30px;
    }
    .img {
      height: 600px !important;
      img:last-child {
        width: 30px;
        height: 30px;
      }
    }
    .car {
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .mainlist .page-box {
    margin-top: 100px;
  }
}



// @media screen and(max-width: 1000px) {
//   .product .productlast {
//     width: 30%;
//     height: 600px;
//     margin-left: 20px;
//     .font p:last-child {
//       height: 30px;
//       line-height: 30px;
//     }
//   }
// }
</style>
